import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { Trans, useTranslation } from 'next-i18next'
import { useEffect, useContext, useState } from 'react'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'

import { Page } from '../components/Page'
import { MarketingNewsLetter } from '../components/Marketing/MarketingNewsLetter'
import { SimpleMarketingSplashSection } from '../components/Sections/SimpleMarketingSplashSection'
import { useAnalyticsEvent } from '../hooks/analyticsEvent'
import { MarketingSplashSection } from '../components/Sections/MarketingSplashSection'
import { colors, mobileScreen } from '../utils'
import { UserContext } from '../context'
import { GroupModal } from '../components/GroupModal'
import { GroupModalType } from '../types'
import { MarketingImageSlider } from '../components/Marketing/MarketingImageSlider'
import { Section } from '../components/Section'
import { FadeInScrollController } from '../components/Spring/FadeInScrollController'
import {
  FadeInController,
  FROM_BOTTOM,
} from '../components/Spring/FadeInController'
import { BowedHeroHeadingTextSection } from '../components/Sections/BowedHeroHeadingTextSection'
import { usePaymentGateway } from '../hooks/usePaymentGateway'
import { px_par } from '../utils/styles'
import { H1, P } from '../components/Headings'
import { CTA } from '../components/CTA'

export const indexPageStyles = css`
  /*
  * Supports the MarketingImageSliderSection so that the
  * extra width is not overflowing the rest of the page
  */
  overflow: hidden;

  .BowedHero {
    /* background: red; */
    .heroText {
      max-width: 710px !important;
    }

    ${H1} {
      font-size: 50px;
      margin-top: 0px !important;
      ${mobileScreen(css`
        font-size: 36px;
      `)}
    }

    .FadeInController {
      width: 100%;
    }

    ${CTA} {
      /* Center and spacing fix for custom fade in control */
      margin: auto;
      display: block;
      /* Some extra padding for the sign up button */
      margin-bottom: 10px;

      ${mobileScreen(css`
        margin-bottom: unset;
      `)}
    }

    .MarketingGroupSignup {
      margin-top: ${px_par(1300, 50)};
      margin-bottom: ${px_par(1300, 90)};
      ${mobileScreen(css`
        margin-top: ${px_par(335, 50)};
        margin-bottom: ${px_par(335, 0)};
      `)}
    }
  }

  .MarketingImageSliderSection {
    /* Move above so it is on the hero above */
    margin-top: -65px;

    /* Override how the section behaves so that it's larger on mobile */
    ${mobileScreen(css`
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
    `)}
  }

  .first-MarketingSplashSection {
    padding-top: 100px;
  }

  .MarketingSplashSection + .MarketingSplashSection {
    padding-top: 30px;
  }
`

const PageContainer = styled(Page)`
  ${indexPageStyles}
`

// Index Page.
const IndexPage: React.FC = () => {
  const { t } = useTranslation('common')
  // GLobal State
  const analyticsEvent = useAnalyticsEvent()
  const router = useRouter()
  const { currentUser } = useContext(UserContext)

  // Local State
  const [showCreateStripeGroup, setShowCreateStripeGroup] = useState(false)
  const [showCreateGroup, setShowCreateGroup] = useState(false)
  const [showAddStripe, setShowAddStripe] = useState(false)

  // Calc
  const userIsLoggedIn = currentUser !== null ? true : false

  // Funcs
  const onGetStartedClick = () => {
    if (userIsLoggedIn === true) {
      router.push(`/myevents/hosting`)
    } else {
      router.push(`/signup`)
    }
  }

  const { isSetupByGroup } = usePaymentGateway()

  // Go to or open correct modal for the payed event CTA
  const onCreatePaidEvent = () => {
    if (userIsLoggedIn === true) {
      // in the case there is no stripe account set up
      // Open modal for group or stripe set up
      if (!isSetupByGroup(currentUser?.managed_groups[0])) {
        if (currentUser?.managed_groups[0]?.id !== undefined) {
          setShowAddStripe(true)
        } else {
          setShowCreateStripeGroup(true)
        }
        // Else if group and stripe set up then take user to create
      } else {
        router.push(`/events/create`)
      }
    } else {
      router.push(`/signup`)
    }
  }

  const onCustomProfilePage = () => {
    // If logged in we can go to a group
    if (userIsLoggedIn === true) {
      // if no group then take to set up
      if (currentUser?.managed_groups[0]?.id === undefined) {
        setShowCreateGroup(true)
      } else {
        //  If no group take to group page
        router.push(`/pages/${currentUser?.managed_groups[0]?.url}`)
      }
    } else {
      // If not member then take user to onboarding
      router.push(`/signup`)
    }
  }

  // On Mount - fire analytics and get an updated user obj
  useEffect(() => {
    analyticsEvent.websiteVisit('homepage')
  }, [])

  // ..
  return (
    <>
      <PageContainer>
        {/* Hero element and form */}
        <BowedHeroHeadingTextSection onGetStartedClick={onGetStartedClick} />
        {/* When new onboarding exits then replace underChild in above */}
        {/* underChild={
            // <FadeInController 
            //   fadeType={FROM_BOTTOM}
            //   children={[
            //     <MarketingGroupSignup />
            //   ]}
            // />
          } */}

        {/* The group name form - coming soon  */}
        <Section className="MarketingImageSliderSection">
          <FadeInController fadeType={FROM_BOTTOM}>
            <MarketingImageSlider />
          </FadeInController>
        </Section>

        {/* List of Feature Cards */}

        {/* Item 1 */}
        <SimpleMarketingSplashSection
          className={'first-MarketingSplashSection MarketingSplashSection'}
          cardType="image-reversed"
          title={
            <FadeInScrollController>
              {t(`index-217370092`, `Monetize your online events`)}
            </FadeInScrollController>
          }
          text={
            <FadeInScrollController>
              <Trans i18nKey="index20211224155003161957293">
                Integrate Klyk with your <b>Zoom account</b> , or utilise other
                video call platforms*, to easily monetize your online events
                today.
              </Trans>
            </FadeInScrollController>
          }
          splashImage={'/images/index-image-1-b.png'}
          splashImageMobile={'/images/index-image-1.png'}
          bgColor={colors.seaGreen}
          ctaText={t(`Create a paid event`, `Create a paid event`)}
          onCtaClick={onCreatePaidEvent}
          centerSplash={false}
          fadeInCta={true}
        />

        {/* Item 2 */}
        <MarketingSplashSection
          className={'MarketingSplashSection'}
          cardType="image-bottom"
          title={
            <FadeInScrollController>
              {t(
                `index-1139958822`,
                `Market your events with a custom profile page`,
              )}
            </FadeInScrollController>
          }
          text={
            <FadeInScrollController>
              {t(
                `index-1575639804`,
                `Your custom profile page notifies your followers about your upcoming events. It’s as simple as that – so all you have to do is create it.`,
              )}
            </FadeInScrollController>
          }
          splashImage={'/images/hosting-groups.png'}
          splashImageMobile={'/images/hosting-groups.png'}
          bgColor={colors.paleOrange}
          ctaText={t(`Create your own`, `Create your own`)}
          onCtaClick={onCustomProfilePage}
          centerSplash={false}
          fadeInCta={true}
        />

        {/* Item 3 */}
        <MarketingSplashSection
          className={'MarketingSplashSection'}
          cardType="image-bottom-reversed"
          title={
            <FadeInScrollController>
              {t(`index-138788350`, `Make the most out of every spot`)}
            </FadeInScrollController>
          }
          text={
            <FadeInScrollController>
              {t(
                `index648846844`,
                `Manage your invites and registrations, and waitlist booked out events to secure a full house.`,
              )}
            </FadeInScrollController>
          }
          splashImage={'/images/index-image-3.png'}
          splashImageMobile={'/images/index-image-3.png'}
          bgColor={colors.washedPurple}
          ctaText={t(`Get Started`, `Get Started`)}
          onCtaClick={onGetStartedClick}
          centerSplash={false}
          fadeInCta={true}
        />

        {/* Item 4 */}
        <MarketingSplashSection
          className={'MarketingSplashSection'}
          cardType="image-bottom"
          title={
            <FadeInScrollController>
              {t(
                `index-351687832`,
                `Ensure higher attendance with event reminders and notifications`,
              )}
            </FadeInScrollController>
          }
          text={
            <FadeInScrollController>
              {t(
                `index433983684`,
                `Free yourself up to focus on your event. Klyk handles the lead-up experience in the background, from event reminders to notifying attendees when details change. `,
              )}
            </FadeInScrollController>
          }
          splashImage={'/images/index-image-4.png'}
          bgColor={colors.seaGreen}
          ctaText={t(`Get Started`, `Get Started`)}
          onCtaClick={onGetStartedClick}
          centerSplash={false}
          fadeInCta={true}
        />

        {/* Call to Action to get started */}
        {/* <FadeInScrollController> */}
        <MarketingNewsLetter />
        {/* </FadeInScrollController> */}
        {/*
         * Modals Start
         */}
        {/* Not sure why group groupId is not optional as it not used in DropDownGroup */}
        {showCreateStripeGroup && (
          <GroupModal
            close={() => setShowCreateStripeGroup(false)}
            modalType={GroupModalType.NormalStripe}
            groupId={0}
          />
        )}

        {showCreateGroup && (
          <GroupModal
            close={() => setShowCreateGroup(false)}
            modalType={GroupModalType.NormalGroup}
            groupId={0}
          />
        )}

        {showAddStripe && (
          <GroupModal
            close={() => setShowAddStripe(false)}
            modalType={GroupModalType.NormalStripe}
            groupId={currentUser?.managed_groups[0]?.id}
            origin={'/events/create'}
          />
        )}
        {/*
         * Modals End
         */}
      </PageContainer>
    </>
  )
}

// IndexPage.getInitialProps = async context => {}

// Export.
export default IndexPage

// Old func in case we need it
// const onEstimationClick = () => {
//   router.push(`/pricing#${generatePageAnchor('earnings-calculator')}`)
// }

// Link example
{
  /* It’s easier than ever to monetise your events. Sell tickets,
track attendance and figure out a fee that works for everyone with our 
<span> </span>
  <a onClick={onEstimationClick}>ticket price calculator.</a> */
}
export async function getStaticProps({ locale }) {
  const translations = await serverSideTranslations(locale, ['common'])
  return {
    props: {
      ...translations,
    },
  }
}
