import { getData } from 'country-list'

// Comps
// import {countries} from '../../utils/countries'
import { Option } from '../types'

export const countryOptions: Option[] = getData().map((set) => {
  return {
    label: set.name,
    value: set.code,
  }
})

export const countryCodes: string[] = getData().map((set) => {
  return set.code
})
