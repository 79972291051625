// Dependencies.
import styled from 'styled-components'

import { dimensions } from '../utils'

// Page.
export const Page = styled.main`
  box-sizing: border-box;
  min-height: calc(100vh - ${dimensions.navigationBarHeightDesktop});
`
