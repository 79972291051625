import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
// import ReactMarkdown from 'react-markdown'
import ScrollTrigger from 'react-scroll-trigger'
import { useSpring, animated } from 'react-spring'

import useWindowSize from '../../utils/useWindowSize'

// Styles
const Container = styled.span`
  .fade-in-on-scroll {
    display: inline-block;
  }
`

export const FadeInScrollController = (props) => {
  // Props
  const { children, className } = props

  // Global State
  const { height, width } = useWindowSize()
  // Local State
  const containerRef = useRef()
  const [_progress, set_progress] = useState(0)
  const [styles, setStyles] = useSpring(() => ({
    config: { duration: 100 },
    opacity: 1,
    transform: 'translateY(20px)',
  }))

  const onPositionChange = (stEvent) => {
    const { progress, velocity } = stEvent
    //  progress = % that referencing the height object and how much is still on screen
    // not really that helpful but we can use it to trigger the useEffect below
    set_progress(progress)
  }

  // On scroll updated & on screen size change
  useEffect(() => {
    // figure out how far away we are from the top
    const dom = containerRef.current
    const top = dom.getBoundingClientRect()?.top || 0
    const topOffset = (100 / height) * top >= 0 ? (100 / height) * top : 0 // make sure we don't have numbers smaller than 0

    // When enough on screen fade in
    if (topOffset < 95) {
      // ie when 60% from the top === 40% from the bottom

      setStyles({
        opacity: 1,
        transform: 'translateY(0px)',
      })
    } else {
      // when scrolling back up fade out
      setStyles({
        opacity: 0,
        transform: 'translateY(20px)',
      })
    }
  }, [height, width, _progress])

  return (
    <Container
      ref={containerRef}
      className={`FadeInScrollController scroll-trigger ${className || ''}`}>
      <ScrollTrigger
        component="span"
        className={'ScrollTrigger'}
        onProgress={onPositionChange}
        throttleScroll={50}>
        <animated.span
          style={styles}
          className={`fade-in-on-scroll ${className || ''}`}>
          {children}
        </animated.span>
      </ScrollTrigger>
    </Container>
  )
}
