import styled, { css } from 'styled-components'
import { useTranslation } from 'next-i18next'

import { Section } from '../Section'
import { FadeInController, FROM_BOTTOM } from '../Spring/FadeInController'
import { colors, mobileScreen } from '../../utils'
import { H1, P } from '../Headings'
import { backgroundImage, px_par } from '../../utils/styles'
import { CTA } from '../CTA'

interface Props {
  bgImage?: string
  onGetStartedClick: () => void
}

const Container = styled(Section)`
  /* display: flex; */
  position: relative;
  overflow: hidden;

  /* Internal Of the section */
  .content {
    /* Fix the missing spacing not being inline
      * Add spacing to avoid menu
      * because margin above shifts the nav
     */
    padding-top: 100px;

    ${mobileScreen(css`
      padding-top: 80px;
    `)}
  }

  .background {
    position: absolute;
    left: -20px;
    right: -20px;
    top: 100px;
    bottom: 0px;

    margin-left: auto;
    margin-right: auto;
    max-width: 'calc(1300px + 40px)';

    /* background-size: calc(100% - 40px) auto; */
    overflow: hidden;

    border-bottom-left-radius: 90% 25%;
    border-bottom-right-radius: 90% 25%;

    ${mobileScreen(css`
      top: 80px;
      background-size: cover;
      border-bottom-left-radius: 95% 10%;
      border-bottom-right-radius: 95% 10%;
    `)}

    .bg-image {
      position: absolute;
      left: 20px;
      right: 20px;
      top: 0px;
      bottom: 0px;

      /* background: ${(props) => {
        return `url("${props['bgImage']}")` || colors.backgroundWhite
      }}; */
      ${(props) => {
        return props['bgImage'] !== undefined
          ? `background: url("${props['bgImage']}");`
          : ''
      }}
      background: ${colors.purple};
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    /* img {
      ${backgroundImage}
      width: calc(100% + 40px);
      height: calc(100% + 40px);
      object-fit: cover;
    } */
  }

  /*  The comp styles */
  .content-container {
    position: relative;
    width: 100%;

    padding-top: ${(props) => {
      return px_par(1300, 115) // 197 or (280-100)/2
    }};

    padding-bottom: ${(props) => {
      return px_par(1300, 115) // 197
    }};
    /* padding-bottom: ${px_par(1300, 197)}; */

    ${mobileScreen(css`
      /* margin-bottom: 60px; */
      padding-top: ${px_par(375, 140)};
      padding-bottom: ${px_par(375, 140)};
    `)}

    .heroText {
      box-sizing: border-box;
      width: 100%;
      max-width: 589px;

      margin: auto;
      padding: 0 20px;
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;

      ${P} {
        width: 100%;
        max-width: 599px;

        margin-top: ${(props) => {
          return px_par(1300, 30) // 197
        }};

        text-align: center;
        color: ${colors.white};
      }

      ${H1} {
        color: ${colors.white};
        text-align: center;
      }

      ${CTA} {
        /* margin-top: ${px_par(1300, 45)}; */
        margin-top: ${(props) => {
          return px_par(1300, 45) // 197
        }};
      }
    }

    .icon-container {
      position: absolute;
      width: 100%;
      bottom: 0px;
      left: 0px;

      /* padding-bottom: ${px_par(1300, 74)}; */
      padding-bottom: ${(props) => {
        return px_par(1300, 74) // 197
      }};

      display: flex;
      justify-content: center;
      align-items: center;

      ${mobileScreen(
        css`
          display: none;
        `,
      )}

      .heroIconArrow {
        width: 40px;
        height: 40px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 50%;
        background: ${colors.purple};

        .icon-arrow {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-size: cover;
          background-image: url('/icons/icon-arrow-down.svg');
        }
      }
    }
    .description{
      display: flex;
      justify-content: center;
    }
`

export const BowedHeroHeadingTextSection: React.FC<Props> = (props) => {
  // Props
  const { onGetStartedClick } = props

  const { t } = useTranslation('common')

  // ..
  return (
    <Container className="BowedHero" unlockWidthConstraint>
      {/* Full screen width background */}
      <div className="background">
        {/* {show(bgImage !== null && bgImage !== undefined) && <> */}
        <div className="bg-image" />
        {/* </>} */}
      </div>

      {/* Background with bow */}
      <div className="content-container">
        <div className="heroText">
          <FadeInController fadeType={FROM_BOTTOM}>
            <H1 className="heroHeader">
              {t(
                `index-638914959`,
                `Host better online events, turn your passion into profit`,
              )}
            </H1>
            <div className="description">
              <P>
                {t(
                  `index187050638`,
                  `Klyk can integrate with your Zoom account (or Hangouts link) to easily manage your events while growing your audience.`,
                )}
              </P>
            </div>
            <CTA className={'extra-padding-30'} onClick={onGetStartedClick}>
              {t(`Signup`, `Signup`)}
            </CTA>
          </FadeInController>
        </div>
      </div>
    </Container>
  )
}
