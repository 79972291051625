import React, { Children } from 'react'
import { oneOf, any } from 'prop-types'
import { useTransition, animated } from 'react-spring'

export const FROM_LEFT = 'FROM_LEFT'
export const FROM_RIGHT = 'FROM_RIGHT'
export const FROM_TOP = 'FROM_TOP'
export const FROM_BOTTOM = 'FROM_BOTTOM'
export const OPACITY = 'OPACITY'

const left = {
  from: { opacity: 0, transform: 'translateX(-20px)' },
  enter: { opacity: 1, transform: 'translateX(0px)' },
  leave: { opacity: 0, transform: 'translateX(-20px)' },
  trail: 400,
}

const right = {
  from: { opacity: 0, transform: 'translateX(20px)' },
  enter: { opacity: 1, transform: 'translateX(0px)' },
  leave: { opacity: 0, transform: 'translateX(20px)' },
  trail: 400,
}

const bottom = {
  from: { opacity: 0, transform: 'translateY(20px)' },
  enter: { opacity: 1, transform: 'translateY(0px)' },
  leave: { opacity: 0, transform: 'translateY(20px)' },
  trail: 400,
}

const top = {
  from: { opacity: 0, transform: 'translateY(-20px)' },
  enter: { opacity: 1, transform: 'translateY(0px)' },
  leave: { opacity: 0, transform: 'translateY(-20px)' },
  trail: 400,
}

const opacity = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
  trail: 400,
}

export const FadeInController = (props) => {
  const { children, fadeType } = props

  // Re-map children as useTransition has a stupid set key function
  const keyedChildren = Children.map(children, (child, index) => {
    return { reactObjSet: child, key: index }
  })

  let transitionsSettings = {}
  switch (fadeType) {
    case OPACITY:
      transitionsSettings = opacity
      break
    case FROM_TOP:
      transitionsSettings = top
      break
    case FROM_BOTTOM:
      transitionsSettings = bottom
      break
    case FROM_RIGHT:
      transitionsSettings = right
      break
    case FROM_LEFT:
      transitionsSettings = left
      break
    default:
      transitionsSettings = opacity
      break
  }

  const transitions = useTransition(keyedChildren, (child) => child.key, {
    ...transitionsSettings,
  })

  return (
    <>
      {transitions.map(({ item, props, key }) => {
        return (
          <animated.div key={key} style={props} className="FadeInController">
            {item.reactObjSet || null}
          </animated.div>
        )
      })}
    </>
  )
}

FadeInController.defaultProps = {
  fadeType: OPACITY,
  children: null,
}

FadeInController.propTypes = {
  fadeType: oneOf([FROM_LEFT, FROM_RIGHT, FROM_BOTTOM, FROM_TOP, OPACITY]),
  children: any,
}
