// Lib
import React from 'react'
import styled, { css } from 'styled-components'

// Comps
import {
  activeWhen,
  colors,
  fontWeights,
  mobileScreen,
  alphaHex,
} from '../../utils'
import { clearButtonStyles, px_par, backgroundImage } from '../../utils/styles'

// Defs

// Styles
export const marketingSplashSectionStyles = css`
  width: 100%;
  max-width: 1000px;

  margin: auto;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  overflow: hidden;
  background: ${(props) => {
    return props['bgColor'] || 'red'
  }};

  ${mobileScreen(
    css`
      align-items: flex-start;
    `,
  )}
 
  .size-tester {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${mobileScreen(css`
      flex-direction: column;
    `)}
  }

  .splash-text {
    width: 100%;
    max-width: 375px;
    
    margin-top: ${px_par(1000, 75)};
    margin-bottom: ${px_par(1000, 75)};
    margin-left: ${px_par(1000, 60)};

    ${mobileScreen(css`
      max-width: calc(100% - 64px);
      margin-top: 30px;
      margin-bottom: 25px;
      margin-left: unset;
      text-align: center;
    `)}

    {/* Disabled to remove emojis  */}
    .ball {
      width: 60px;
      height: 60px;

      display: inline-flex;
      justify-content: center;
      align-items: center;

      background: ${colors.white};
      border-radius: 30px;

      img {
        flex-grow: 0;
        flex-shrink:0;
        width: 30px;
      }
    }
    
    h3 {
      text-align: left;
      /* margin-top: 30px; */
      ${mobileScreen(css`
        text-align: center;
      `)}

      
    }

    p {
      margin-top: 10px;
      
      /* STD button styles - ignored by contents   */
      button {
        margin-top: 2px;
        pointer-events: all;
        font-weight: ${fontWeights.medium};

        ${mobileScreen(css`
          margin: auto;
        `)}
      }
    }

    .action-button { margin-top: 30px; }
  }

  .splash-image-center {
    width: 100%;
    max-width: ${px_par(1000, 460)};
    margin-right: ${px_par(1000, 60)};
    display: flex;
    align-items: flex-end;

    ${mobileScreen(css`
      max-width: calc(100% - 20px);
      margin: auto;
      margin-right: auto;
    `)}

    img { width: 100%; }
  }

  .splash-image {
    width: 100%;
    max-width: ${px_par(1000, 460)};
    margin-top: 40px;
    align-self: stretch;
    display: flex;
    align-items: flex-end;
    filter: drop-shadow(-13.1092px 32.7731px 26.2185px rgba(0, 0, 0, 0.40));

    ${mobileScreen(css`
      max-width: calc(100% - 20px);
      margin-top: unset;
      margin-bottom: ${px_par(3.5, -1)};
      margin-left: 20px;
    `)}

      img { width: 100%; }
    }
  }

  .splash-video {
    width: ${px_par(1000, 439)};
    margin-top: 60px;
    margin-bottom: 80px;
    margin-right: ${px_par(1000, 60)};
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    ${mobileScreen(css`
      width: calc(100% - 84px);
      margin-right: unset;
      margin: auto;
    `)}

    .video-sizer {
      ${clearButtonStyles}
      position: relative;
      width: 100%;
      padding-top: ${px_par(439, 279)};

      border-radius: 6px;
      overflow: hidden;
      pointer-events: all;
      background: ${colors.white};

      video {
        ${backgroundImage}
        width: 100%;
        height: 100%;

        transition: opacity 0.3s;

        &.active { opacity: 1; }
      }

      .video-placeholder {
        ${backgroundImage}
        width: 100%;
        height: 100%;
      }

      .video-playback-element {
        /* opacity: 0; */
        /* transition: opacity 0.3s; */
        /* &.active { opacity: 1; }  */
      }

      .play-head { 
        position: absolute;
        width: ${px_par(439, 87)};
        top: 50%;
        left: 50%;
        /* opacity: 1;  */
        transform: translateY(-50%) translateX(-50%);
        transition: opacity 0.3s;
        cursor: pointer;

        /* &.inactive { opacity: 0; } */
      }
    }

    .video-description {
      width: 100%;
      margin-top: 15px;
      font-size: 11px;
      color: ${alphaHex(colors.veryDarkGray, 0.5)};
      text-align: center;

      ${mobileScreen(
        css`
          display: none;
        `,
      )}
    }
  }

}
`
