// Dependencies.
import styled from 'styled-components'

import { fontWeights } from '../utils'

// H2.
export const H2 = styled.h2`
  color: #171515;
  font-size: 36px;
  font-weight: ${fontWeights.bold};
  letter-spacing: -0.8px;
  line-height: 110%;
`
