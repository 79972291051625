// Lib
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

// Comps
import {
  activeWhen,
  colors,
  fontWeights,
  mobileScreen,
  alphaHex,
} from '../../utils'
import { clearButtonStyles, px_par, backgroundImage } from '../../utils/styles'

// Styles
export const simpleMarketingSplashStyles = css`
  width: 100%;
  max-width: 1000px;

  margin: auto;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  overflow: hidden;
  background: ${(props) => {
    return props['bgColor'] || 'red'
  }};

  ${mobileScreen(
    css`
      align-items: flex-start;
    `,
  )}
 
  .size-tester {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${(props) => {
      return props['cardType'] === 'image-reversed'
        ? css`
            flex-direction: row-reverse;
            ${mobileScreen(
              css`
                flex-direction: column;
              `,
            )}
          `
        : css`
            flex-direction: row;
            ${mobileScreen(
              css`
                flex-direction: column;
              `,
            )}
          `
    }}
  
  .splash-text {
    width: 100%;
    max-width: 375px;
    
    margin-top: ${px_par(1000, 140)};
    margin-bottom: ${px_par(1000, 140)};
    

   ${(props) => {
     return props['cardType'] === 'image-reversed'
       ? css`
           margin-right: ${px_par(1000, 60)};
         `
       : css`
           margin-left: ${px_par(1000, 60)};
         `
   }};

    ${mobileScreen(css`
      max-width: calc(100% - 64px);
      margin-top: 30px;
      margin-bottom: 25px;
      margin-left: unset;
      text-align: center;
    `)}

    {/* Disabled to remove emojis  */}
    .ball {
      width: 60px;
      height: 60px;

      display: inline-flex;
      justify-content: center;
      align-items: center;

      background: ${colors.white};
      border-radius: 30px;

      img {
        flex-grow: 0;
        flex-shrink:0;
        width: 30px;
      }
    }
    
    h3 {
      text-align: left;
      /* margin-top: 30px; */
      ${mobileScreen(css`
        text-align: center;
      `)}
    }

    p {
      margin-top: 15px;
      
      /* STD button styles - ignored by contents   */
      button {
        margin-top: 2px;
        pointer-events: all;
        font-weight: ${fontWeights.medium};

        ${mobileScreen(css`
          margin: auto;
        `)}
      }

      a {
        font-weight: ${fontWeights.bold};
        pointer-events: all;
      }
    }

    .action-button { margin-top: 30px; }
  }

  .splash-image {
    width: 100%;
    max-width: ${px_par(1000, 470)};
    /* margin-top: 40px; */
    /* ${(props) => {
      return props['cardType'] === 'image-bottom' ||
        props['cardType'] === 'image-bottom-reversed'
        ? css`
            margin-top: 40px;
          `
        : css`
            margin-bottom: 40px;
          `
    }}; */

    align-self: stretch;
    display: flex;
    align-items: flex-end;
    filter: drop-shadow(-13.1092px 32.7731px 26.2185px rgba(0, 0, 0, 0.40));

      ${mobileScreen(css`
        max-width: calc(100% - 20px);
        margin-top: unset;
        /* margin-bottom: ${px_par(3.5, -1)}; */
        margin-top: 0px;
        margin-bottom: 0px;
        /* margin-left: 20px; */
        margin-right: 0px;
      `)}


      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        object-position: right;
      }
    }
  }



}
`
