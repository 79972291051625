// Libs
import styled, { css } from 'styled-components'

// Comps
import { Parallax } from 'react-scroll-parallax'

import useWindowSize from '../../hooks/useWindowSize'
// import {ParallaxController} from '../../components/Spring/ParallaxController'

// Defs
interface Props {}

// Styles
import { marketingImageSliderStyles } from './marketingImageSliderStyles'

const Container = styled.div<Props>`
  ${marketingImageSliderStyles}
`

export const MarketingImageSlider: React.FC<Props> = (props) => {
  // Global State
  const { width } = useWindowSize()

  // Calc
  const isMobile = width < 800 ? true : false
  const yTravel = isMobile == true ? ['-25px', '25px'] : ['-50px', '50px']

  // ..
  return (
    <Container className="MarketingImageSlider">
      <Parallax y={yTravel} disabled={false}>
        <img className="center-image" src="images/index-image-slider-1.png" />
      </Parallax>

      <img className="left-image" src="images/index-image-slider-L.png" />

      <img className="right-image" src="images/index-image-slider-R.png" />

      <div className="space-box" />
    </Container>
  )
}
