import { useTranslation } from 'next-i18next'
// Dependencies
import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'

import { H2 } from '../H2'
import { P } from '../P'
import { Section } from '../Section'
import { CTA } from '../CTA'
import { Hyperlink } from '../Hyperlink'
import { mobileScreen } from '../../utils'
import { useAnalyticsEvent } from '../../hooks/analyticsEvent'
import { FadeInScrollController } from '../Spring/FadeInScrollController'

// Props
interface Props {}

// Component
export const MarketingNewsLetter: React.FC<Props> = (props) => {
  const { t } = useTranslation('common')

  // ..
  return (
    <Div>
      <Section>
        <div className="centered-box">
          <H2>
            <FadeInScrollController>
              {t(
                `MarketingNewsLetter-821239321`,
                `Join the Klyk creator community`,
              )}
            </FadeInScrollController>
          </H2>
          <P>
            <FadeInScrollController>
              {t(
                `MarketingNewsLetter1480882593`,
                `Sign up to receive the latest best practices, news, and product updates.`,
              )}
            </FadeInScrollController>
          </P>

          <FadeInScrollController>
            <iframe
              frameBorder="0"
              src="https://cdn.forms-content.sg-form.com/b886cf16-b9c5-11eb-8ade-0a03a2d318cd"
            />
          </FadeInScrollController>
        </div>
      </Section>
    </Div>
  )
}

// Props

// Styled Components
const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  * {
    text-align: center;
  }
  .centered-box {
    padding-top: 150px;
    padding-bottom: 70px;
    /* adjust padding because of form */
    /* padding-bottom: 150px; */

    ${mobileScreen(css`
      padding-top: 100px;
      padding-bottom: 100px;
    `)}

    h2 {
      margin: auto;
      padding-bottom: 25px;
    }

    p {
      width: 100%;
      /* max-width: 437px; */
      /* padding-bottom: 30px; */
    }

    .FadeInScrollController {
      width: 100%;

      .fade-in-on-scroll {
        width: 100%;

        iframe {
          width: 100%;
          height: 305px;
        }
      }
    }
  }
`
