// Lib
import React, { useState } from 'react'
import styled from 'styled-components'

// Comps
import { Section } from '../Section'
import { MarketingSplash, Props } from '../Marketing/MarketingSplash'

// Defs
export type OnTabChangeFunc = (tab: number) => void

// Styles
import { marketingSplashSectionStyles } from './marketingSplashSectionStyles'

const Container = styled(Section)`
  /* ${marketingSplashSectionStyles} */
  width: 100%;
  .content {
  }
`

export const MarketingSplashSection: React.FC<Props> = (props) => {
  // Props
  const { onCtaClick, className } = props

  // Funcs
  const theOnCtaClick = () => {
    if (onCtaClick) {
      onCtaClick()
    } else {
      console.warn('@EventEmptySplash->onCtaClick not set')
    }
  }

  const patchedProps = {
    ...props,
    onCtaClick: theOnCtaClick,
    className: '',
  }

  // ..
  return (
    <Container className={`MarketingSplashSection ${className || ''}`}>
      <MarketingSplash {...patchedProps} />
    </Container>
  )
}
