// Lib
import React, { useState, useRef, RefObject, useEffect } from 'react'
import styled from 'styled-components'

import useWindowSize from '../../utils/useWindowSize'

// Comps
import { when, show, showFinally, screens } from '../../utils'
import { H3, P } from '../Headings'
import { FadeInScrollController } from '../Spring/FadeInScrollController'
// import {H3} from './H3'
// import {P} from './P'
import { CTA } from '../CTA'

// Defs
export interface Props {
  title?: string | React.ReactNode
  text?: string | React.ReactNode
  splashImage?: string
  splashImageMobile?: string
  bgColor?: string
  ctaText?: string
  onCtaClick?: () => void
  centerSplash?: boolean
  className?: string
  noCTA?: boolean
  cardType:
    | 'image'
    | 'image-reversed'
    | 'image-bottom'
    | 'image-bottom-reversed'
  fadeInCta?: boolean
}

/**
 * Based on EventEmptySplash
 * - some parts stripped out ie video support & center image
 * - added support for positioning image on both lef/right and top/bottom
 * - should be compatible with the Carousel but not tested
 * Replaces ContentSlides that was static and partly broken on mobile
 */

export type OnTabChangeFunc = (tab: number) => void

// Styles
import { marketingSplashStyles } from './marketingSplashStyles'

const Container = styled.div<Props>`
  ${marketingSplashStyles}
`

export const MarketingSplash: React.FC<Props> = (props) => {
  // Props
  const {
    title,
    text,
    splashImage,
    bgColor,
    ctaText,
    splashImageMobile,
    onCtaClick,
    centerSplash,
    className,
    noCTA,
    cardType,
    fadeInCta,
  } = props

  // Global State
  const { width } = useWindowSize()

  // Calc
  const isMobile = width <= screens.mobileAsNumber ? true : false
  const image =
    isMobile && splashImageMobile != undefined && splashImageMobile != null
      ? splashImageMobile
      : splashImage

  // Funcs
  const theOnCtaClick = () => {
    if (onCtaClick) {
      onCtaClick()
    } else {
      console.warn('@EventEmptySplash->onCtaClick not set')
    }
  }

  // ..
  return (
    <Container
      className={`MarketingSplash ${cardType || 'image'} ${className || ''}`}
      bgColor={bgColor}
      centerSplash={centerSplash}
      cardType={cardType || 'image'}>
      {/* Container is used to keep the size the same in a carousel */}
      <div className="size-tester">
        {/* Left */}
        <div className="splash-text">
          <H3 style={{ pointerEvents: 'none' }}>{title || ''}</H3>
          <P style={{ pointerEvents: 'none' }}>{text || ''}</P>
          {show(noCTA !== true) && (
            <>
              {show(fadeInCta === true) && (
                <>
                  <FadeInScrollController>
                    <CTA
                      onClick={theOnCtaClick}
                      className="action-button clear-black outline-very-dark-grey-02 extra-padding-20 fixed-min-width disabled-hidden">
                      {ctaText || ''}
                    </CTA>
                  </FadeInScrollController>
                </>
              )}
              {show(fadeInCta !== true) && (
                <>
                  <CTA
                    onClick={theOnCtaClick}
                    className="action-button clear-black outline-very-dark-grey-02 extra-padding-20 fixed-min-width disabled-hidden">
                    {ctaText || ''}
                  </CTA>
                </>
              )}
            </>
          )}
        </div>

        {/*  Right Image Option */}
        <div className="splash-image">
          <img src={image || ''} style={{ pointerEvents: 'none' }} />
        </div>
      </div>
    </Container>
  )
}
