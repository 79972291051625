// Libs
import styled, { css } from 'styled-components'

// Comps
import {
  colors,
  forScreensGreaterThanMobileWidth,
  mobileScreen,
  icons,
} from '../../utils'
import { backgroundImage, px_par } from '../../utils/styles'

const maxWidth = 1144

export const marketingImageSliderStyles = css`
  position: relative;
  width: 100%;
  max-width: 1144px;
  margin: auto;

  .space-box {
    display: block;
    width: ${px_par(maxWidth, 733)};
    padding-top: ${px_par(maxWidth, 120 + 447)};
    margin: auto;
    /* background: red; */
  }

  .center-image {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: ${px_par(maxWidth, 733)};
    height: auto;

    margin: auto;

    box-shadow: 0px 17.1487px 34.2975px rgba(0, 0, 0, 0.1);

    /* ${mobileScreen(css`
      width: 100%;
    `)} */
  }

  .left-image {
    position: absolute;
    top: 0px;
    left: 0px;

    margin-top: ${px_par(maxWidth, 215)};
    width: ${px_par(maxWidth, 305)};

    box-shadow: 0px 17.1487px 34.2975px rgba(0, 0, 0, 0.1);

    /* ${mobileScreen(css`
      display: none;
    `)} */
  }

  .right-image {
    position: absolute;
    top: 0px;
    right: 0px;
    margin-top: ${px_par(maxWidth, 170)};
    margin-right: ${px_par(maxWidth, -20)};

    width: ${px_par(maxWidth, 354)};

    box-shadow: 0px 17.1487px 34.2975px rgba(0, 0, 0, 0.1);

    /* ${mobileScreen(css`
      display: none;
    `)} */
  }
`
