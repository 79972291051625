// Libs
import validator from 'validator'

import { countryCodes } from './countries'
import { Option, Invite, User } from '../types'
import { EmailList } from './emails'

export const Validator = {
  // The validation library
  ...validator,

  // A rule for checking to see if a separated list was parsed correctly
  // Can accept a n array of EmailList or Options
  // Dose not validate if the contents of emails in the list
  // it only looks at just if the number of emails in the string match what was parsed
  didEmailParseCorrectly: function (
    str: string,
    emailList: EmailList | Option[],
  ) {
    let emptyParse = false

    const testStr = str || ''
    // remove a separator if it is on the end
    if (
      testStr[testStr.length - 1] === ',' ||
      testStr[testStr.length - 1] === ';'
    ) {
      testStr.substring(0, testStr.length - 1)
    }

    // console.log()

    const separators =
      (testStr.match(/,/g)?.length || 0) + (testStr.match(/;/g)?.length || 0)
    const emailsCount = separators // because we have 1 less separator than emails

    //  if the parsed had any empties
    emailList.forEach((hybridItem) => {
      if (!hybridItem?.address && !hybridItem?.value) {
        emptyParse = true
      }
    })
    // console.log(emailsCount, emailList.length, emptyParse, emailList)

    // return success  or fail
    return emailsCount === emailList.length && emptyParse === false
      ? true
      : false
  },

  // Look at an email to see if it valid
  isEmailAndRequired: function (str: string) {
    let testString = str || ''
    // remove any spaces so we can trip validation if we have ' '
    testString = testString.trim()
    return Validator.isEmail(testString) === true &&
      Validator.isEmpty(testString) === false
      ? true
      : false
  },

  // check to see if invites are spam
  isInvitesNotSpam: function (invites: Invite[], limit: number) {
    // const theLimit = limit
    if (this.isArray(invites)) {
      if (invites.length <= limit) {
        return true
      }
    }

    return false
  },

  isArray: function (test: any) {
    return test && Array.isArray(test) === true ? true : false
  },

  // if value of country is correct
  isCountryCode: function (test: any) {
    if (countryCodes.includes(test)) {
      return true
    }
    return false
  },

  isOptionCountryCode: function (test: any) {
    const opt = test as Option // cast option - it could be anything including null or undefine
    // see if matches option pattern and if value is a country code
    if (
      opt &&
      opt?.value &&
      opt.label &&
      this.isCountryCode(opt.value) === true
    ) {
      return true
    }
    return false
  },

  isNotEmptyString: function (test: any) {
    if (
      test !== undefined &&
      test !== null &&
      test !== '' &&
      typeof test === 'string'
    ) {
      return true
    }
    return false
  },

  isDefined: function (test: any) {
    if (test !== undefined && test !== null) {
      return true
    }
    return false
  },
}

// Helper tools fior validating and forms
export const Form = {
  // check to see if any errors exists
  isFreeOfErrors: function (test: string[][]) {
    let hasErrors = true
    // Look at all the errors and check to make sure they are empty
    test.forEach((errorSet) => {
      if (errorSet.length > 0) {
        hasErrors = false
      }
    })

    return hasErrors
  },

  // check to see if all required values are there
  hasAllValues: function (keyList: string[], values: Record<string, unknown>) {
    let hasAllValues = true
    // loop through and check that all has values
    keyList.forEach((key) => {
      const test = values[key] || undefined
      if (test === undefined || test === null || test === '') {
        hasAllValues = false
      }
    })
    return hasAllValues
  },
  mapErrors: function (errors: string[]) {
    return (
      <>
        {errors.map((error, index) => {
          return <p key={index}>{error}</p>
        })}
      </>
    )
  },
}

export const Message = {
  errorsAbove: 'There are errors above that need solving.',
  sendFailure: 'There was an issue sending your request. Please try again.',
  emailRequired: 'The Email Field is required',
  emailInvalid: 'The Email Field must be an email.',
  countryRequired: 'The Country Filed is required.',
  countryInvalid: 'The Country is not a valid option.',
}
